import getOptionById from "./getOptionById";
import {translate} from "./translations";
import {getVerandaValues} from "./getVerandaValues";

export default function (data, store) {
  const { language, houseId, blocks, region} = store;

  const result = {};
  const houseData = data['houses'][houseId];

  // First table header
  let firstHeader = `${translate('THE KIT OF THE HOUSE', language)} `;

  // Does the house have a veranda
  const verandaBlockNames = [
    'koshaus_main_veranda',
    'cubehaus_main_veranda',
    'dublhaus_main_veranda',
    'additional_veranda',
    'dublhaus_additional_veranda',
  ];

  let verandaSize;

  for (let blockName of verandaBlockNames) {
    if (blocks.hasOwnProperty(blockName)) {
      const activeOptionId = blocks[blockName].active_index;

      const blockData = data.blocks.find( block => (
        block.machine_name === blockName
      ));

      if (typeof blockData === "undefined") {
        throw new Error(`Block "${blockName}" was not found`)
      }

      const activeOption = getOptionById(blockData, activeOptionId);
      const { width, height } = getVerandaValues(activeOption, region, houseId);
      if (width && height){
        verandaSize = `${width} x ${height}`;
        break
      }
    }
  }

  if (typeof verandaSize !== "undefined") {
    firstHeader += `${translate('WITH A VERANDA', language)} `;
  }

  // Get house class
  let houseClass = 'Class A';
  if (blocks.hasOwnProperty('wall_improvement')) {
    const classActiveIndex = blocks['wall_improvement'].active_index;
    const classBlockData = data.blocks.find( block => (block.machine_name === 'wall_improvement'));
    const classActiveOption = getOptionById(classBlockData, classActiveIndex);
    houseClass = classActiveOption.label;
  }

  //Initial first table header
  result[firstHeader] = [];

  // Type of house option
  const houseModel = data['houses'][houseId].model.toUpperCase();
  const houseModelNum = data['houses'][houseId].name.match(/[0-9]+/)[0];
  const houseName = `${houseModel} ${houseModelNum}`;

  // Add Type of house option
  result[firstHeader].push(
    `${translate('Type of house', language)}: `+
    `${houseName}`
  );

  // Add Veranda option
  if (typeof verandaSize !== "undefined") {
    result[firstHeader].push(
      `${translate('Veranda', language)}: `+
      `${verandaSize} ${translate('m', language)} `+
      `${translate('with larch decking', language)}`
    );
  }

  //Initial second table header
  const secondHeader = translate('THE COMPLETE SET OF THE BASE HOUSE (INCLUDED IN PRICE)', language);
  result[secondHeader] = [];

  // insulation configuration
  let floor = `${translate('Floor insulation', language)} – `;
  let wall = `${translate('wall insulation', language)} – `;
  let roof = `${translate('roof insulation', language)} – `;

  let houseClassIsCorrect = true;
  switch (houseClass) {
    case 'Class A+': {
      floor += '250';
      wall += '250';
      roof += '400';
      break;
    }

    case 'Class A': {
      floor += '250';
      wall += '200';
      roof += '300';
      break;
    }

    case 'Class B': {
      floor += '200';
      wall += '150';
      roof += '200';
      break;
    }

    default: {
      houseClassIsCorrect = false;
    }
  }

  if (!houseClassIsCorrect) {
    throw new Error('Energy efficiency class at home not defined');
  }

  floor += ` ${translate('mm', language)}`;
  wall += ` ${translate('mm', language)}`;
  roof += ` ${translate('mm', language)}`;

  result[secondHeader].push(
    `${translate(houseClass, language)}: ${floor}, ${wall}, ${roof}`
  );

  // Add some options
  result[secondHeader].push(
    translate('Wooden frames of windows and wooden doors with triple glazing including low-e glass', language),
    translate('Side panoramic windows with triple glazing including low-e glass', language),
  );

  // Add options for Koshaus
  if (houseModel === 'KOSHAUS') {
    // Retention and drainage system check
    let systemCheck = false;

    // @TODO fix Retention and drainage system check
    if (systemCheck) {
      const roofCoverBlockData = data['blocks'].find( block => ( block.machine_name === 'koshaus_roof_cover'));
      const options = roofCoverBlockData.values.options;
      const systemOptionId = options.find( option => (
        option.label === 'Snow retention and drainage system from the side of the second slope of the roof'
      )).machine_name;

      if (blocks['koshaus_roof_cover'].hasOwnProperty(systemOptionId)) {
        if (blocks['koshaus_roof_cover'][systemOptionId].checked) {
          systemCheck = true;
        }
      } else if (Number(blocks['koshaus_roof_cover'].active_index) === Number(systemOptionId)) {
        systemCheck = true;
      }

      if (systemCheck) {
        result[secondHeader].push(
          translate('Gutter system from the side of the panoramic windows', language),
          translate('Snow shields from the side of the panoramic windows', language),
        );
      }
    }

    // Add Floor cover option
    result[secondHeader].push(
      translate('Basic flooring: laminate with plinth', language),
    );

  }

  // Add some options
  result[secondHeader].push(
    translate('Interior finish: pine cladding', language),
    translate('Engineering networks: electrical wiring (built into the walls), electrical service panel, sockets, switches', language),
    translate('Outdoor and indoor lighting', language),
    translate('Engineering networks: plumbing and sewage', language),
    translate('Bathroom equipment: bath with shower, washbasin with pedestal, toilet bowl, GROHE faucets.\nHeated floor (warm floor)', language),
    translate('Bathroom decoration with ceramic mosaic and pine cladding', language),
    translate('Electric water heater, accumulative: Thermex Mechanik MK, 100 liters', language),
    translate('Protection against rodents: Cement bonded particle board under the entire floor area', language),
  );

  return result
}